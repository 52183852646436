import React, { Component } from "react";

import PortfolioSingle from "./components/PortfolioSingle";

import "./styles/App.css";
import "./styles/index.css";

const clients = [
  // {
  //   title: "Orderutan Merchant Dashboard",
  //   subtitle: "Web and Mobile App Development",
  //   desc: "Order management system for merchants to automate their online business.",
  //   tags: ["Website", "Mobile", "Responsive", "Messaging", "Payments"],
  //   image: "/images/orderutan.png",
  //   clientName: "Orderutan",
  //   clienthttp: "https://orderutan.com",
  //   clientFace:
  //     "https://scontent.fkul15-1.fna.fbcdn.net/v/t31.0-8/12339381_10156379421830397_6245559235667282000_o.jpg?_nc_cat=0&oh=4a9865e4e8ebd0ffd06e2bdd23e68d73&oe=5BA20C28",
  //   review: "This is an in-house project that we're deeply passionate for.",
  //   client: "Adam Wan, Awan Ventures Founder",
  //   platforms: "Web, iOS & Android",
  //   tech: "React, React Native, GraphQL, ExpressJS",
  //   readMore:
  //     "Full-stack development from scratch of both front-end and back-end, as well as graphic design and UI UX of the entire platform.",
  // },
  {
    title: "Tourplus Tour Guide App",
    subtitle: "App Enhancement (Front End)",
    desc: "Online platform that matches tourists with tour guides.",
    tags: ["Mobile", "Social", "Messaging", "Payments", "Geolocation"],
    image: "/images/tourplus.png",
    clientName: "Tourplus",
    clienthttp: "https://tourplus.my",
    clientFace:
      "https://media.licdn.com/media-proxy/ext?w=264&h=264&f=n&hash=vLRvFdZ2ELkPe4TTR9lur6VF8GY%3D&ora=1%2CaFBCTXdkRmpGL2lvQUFBPQ%2CxAVta5g-0R6rgxJUxRU14qGOpgGz60oIRJfICm_jRH7jpoDWPyj2KIGOPePj",
    review:
      "Adam has been very professional and well communicated along the way. Good quality of work! We wouldn't be able to meet the deadline without his help.",
    client: "Kyle Foo, Tourplus CTO",
    platforms: "iOS & Android",
    tech: "React Native, GraphQL",
    readMore:
      "Working together with Tourplus' design and development teams to produce front-end enhancement on existing iOS & Android apps.",
  },
  {
    title: "1337 Accelerator Tools",
    subtitle: "Web App Development",
    desc: "Online platform used as an educational tool for startup accelerator programmes.",
    tags: ["Website", "Social"],
    image: "/images/1337.png",
    clientName: "1337 Accelerator",
    clienthttp: "https://1337accelerator.com",
    clientFace:
      "https://scontent.fkul15-1.fna.fbcdn.net/v/t1.0-9/300933_10150274876187909_3009991_n.jpg?_nc_cat=0&oh=10be29c4e6635336f383897f7c2c61f8&oe=5BA16A7E",
    //review: 'All the requirements and bugs were being attended to and solved very quickly.',
    client: "Mike Bikesh, 1337 Ventures CEO",
    platforms: "Web",
    tech: "React, Firebase, NodeJS",
    readMore:
      "A 6 week project involving full-stack development of both front-end and back-end from scratch. Includes UI UX design.",
  },
  // {
  //   title: "Jio Events App",
  //   subtitle: "Mobile App Development",
  //   desc: "Online platform to find events, make bookings, and get promotions near you.",
  //   tags: ["Mobile", "Social", "Geolocation"],
  //   image: "/images/jio.png",
  //   clientName: "JioLah",
  //   clienthttp: "https://jiolah.com",
  //   clientFace:
  //     "https://scontent.fkul15-1.fna.fbcdn.net/v/t31.0-8/13938106_10206071617979468_6218033119365138950_o.jpg?_nc_cat=0&oh=f788355dcd52351f68e89b1246c793f3&oe=5BA3BBAF",
  //   review: "Communication was great! Working with you guys was smooth.",
  //   client: "Kenneth Liew, JioLah Founder",
  //   platforms: "iOS & Android",
  //   tech: "React, React Native, Firebase, NodeJS",
  //   readMore:
  //     "Full-stack development of iOS & Android apps from scratch based on an existing mockup design.",
  // },
  // {
  //   title: "Go Live Social App",
  //   subtitle: "Mobile App Development",
  //   desc: "Social media platform to organise events with your friends, with news feeds, likes, sharing, and real-time chat.",
  //   tags: ["Mobile", "Social", "Messaging", "Geolocation"],
  //   image: "/images/golive.png",
  //   clientName: "Go Live",
  //   clienthttp: "https://golive-app.com",
  //   clientFace:
  //     "https://scontent.fkul15-1.fna.fbcdn.net/v/t31.0-8/26840962_10214979451055556_1855911082320097359_o.jpg?_nc_cat=0&oh=37668eec4965a31fb181248a8db22653&oe=5BECAC1E",
  //   review:
  //     "All the requirements and bugs were being attended to and solved very quickly.",
  //   client: "Chun Kit, Go Live Co-Founder",
  //   platforms: "iOS & Android",
  //   tech: "React, React Native, Firebase, NodeJS",
  //   readMore:
  //     "Full-stack development of iOS & Android apps from scratch based on an existing mockup design.",
  // },
  // {
  //   title: "SideQuester",
  //   subtitle: "Website and Mobile Development",
  //   desc: "A jobs platform for artists with social media features.",
  //   tags: [
  //     "Website",
  //     "Mobile",
  //     "Responsive",
  //     "Social",
  //     "Messaging",
  //     "Geolocation",
  //   ],
  //   image: "/images/sidequest.png",
  //   clientName: "",
  //   clienthttp: "https://adamthewan.com",

  //   platforms: "Web, iOS & Android",
  //   tech: "React, React Native, MeteorJS",
  //   readMore:
  //     "Full-stack development of web platform from scratch, and prototype of iOS & Android apps. Includes graphic design and UI UX of the entire platform.",
  // },
  {
    title: "Pulse by Prudential",
    subtitle: "Web App Development",
    desc: "Entire Landing Page for Pulse by Prudential website launch.",
    tags: ["Website", "Responsive"],
    image: "/images/prudential.png",
    clientName: "",
    clienthttp: "https://wedopulse.com/my/?utm_language=my",

    platforms: "Web, iOS & Android",
    tech: "React, Gatsby, HTML, CSS, JavaScript, il8n",
    readMore:
      "Front end development of web platform from scratch. Includes complex animations, language localization and GTM integration.",
  },
  {
    title: "Hong Leong Bank",
    subtitle: "Website Development",
    desc: "Hong Leong Bank Landing Page for Emirates Credit Card Rewards campaign.",
    tags: ["Website", "Responsive"],
    image: "/images/hlb.png",
    clientName: "",
    clienthttp:
      "https://www.hlb.com.my/en/personal-banking/credit-cards/travel/emirates-credit-card.html",

    platforms: "Web, iOS & Android",
    tech: "HTML, CSS",
    readMore:
      "A quick project developing a page outlining essential credit card details.",
  },
  // {
  //   title: "Pollen",
  //   subtitle: "Website Development",
  //   desc: "A community driven marketplace for emerging businesses.",
  //   tags: ["Website", "Responsive"],
  //   image: "/images/pollen.png",
  //   clientName: "",
  //   clienthttp: "https://adamthewan.com",

  //   platforms: "Web, iOS & Android",
  //   tech: "React, React Native, MeteorJS",
  //   readMore:
  //     "Full-stack development of web platform from scratch, and prototype of iOS & Android apps. Includes graphic design and UI UX of the entire platform.",
  // },
  // {
  //   title: "StreamCrux",
  //   subtitle: "Website Development",
  //   desc: "Social media platform for streamers. Front end development on React.",
  //   tags: ["Website", "Responsive", "Social"],
  //   image: "/images/StreamCrux.png",
  //   clientName: "",
  //   clienthttp: "https://streamcrux.com",

  //   platforms: "Web, iOS & Android",
  //   tech: "React, Redux, Firebase, C++",
  //   readMore:
  //     "Full-stack development of web platform from scratch, and prototype of iOS & Android apps. Includes graphic design and UI UX of the entire platform.",
  // },
  {
    title: "Xolas Systems with D’Harmoni Sdn Bhd",
    subtitle: "Website Development",
    desc: "Software development for telecommunications tower management web app.",
    tags: ["Website", "Responsive"],
    image: "/images/xolas.png",
    clientName: "",
    clienthttp: "https://adamthewan.com",

    platforms: "Web, iOS & Android",
    tech: "Vue, PHP Laravel",
    readMore:
      "Full-stack development of web platform from scratch, and prototype of iOS & Android apps. Includes graphic design and UI UX of the entire platform.",
  },
  {
    title: "Pod Savings",
    subtitle: "Website Development",
    desc: "An app that make savings easier. Worked on Backend support and integration.",
    tags: ["Website", "Responsive"],
    image: "/images/pod.png",
    clientName: "",
    clienthttp: "https://adamthewan.com",

    platforms: "iOS & Android",
    tech: "Ruby",
    readMore:
      "Full-stack development of web platform from scratch, and prototype of iOS & Android apps. Includes graphic design and UI UX of the entire platform.",
  },
  {
    title: "Moovby",
    subtitle: "Mobile App",
    desc: "Car rental app platform. worked on admin panel to manage car hosts.",
    tags: ["Website", "Responsive"],
    image: "/images/moovby.png",
    clientName: "",
    clienthttp: "https://moovby.com",

    platforms: "iOS & Android",
    tech: "React, Redux",
    readMore:
      "Full-stack development of web platform from scratch, and prototype of iOS & Android apps. Includes graphic design and UI UX of the entire platform.",
  },
];

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: null,
    };
  }

  render() {
    return (
      <div className="page-body container" style={{ paddingTop: "7em" }}>
        <div
          className="relative center-block"
          style={{ paddingBottom: "4em", maxWidth: "600px" }}
        >
          <div className="text-center">
            <img
              src={"/images/awan-ventures-long.png"}
              alt=""
              style={{ width: "320px", height: "auto" }}
              className=" "
            />
          </div>

          <div className="fontW500 text-center font28 gradient-front">
            Ideas Take Flight
          </div>
        </div>

        <section className="" style={{ padding: "6em 1em 6em 1em" }}>
          <div className="container relative" style={{ maxWidth: "600px" }}>
            <h2
              className="fontW600 text-center font32 p-b-2"
              style={{ color: "#ABCAFF" }}
            >
              WE MAKE{" "}
              <span
                className=" inline-block orange-hover"
                style={{ color: "#7BB0FF" }}
              >
                APPS & WEBSITES,
              </span>
              <br />
              FOR YOUR{" "}
              <span
                className=" orange-hover inline-block"
                style={{ color: "#7BB0FF" }}
              >
                BIG IDEAS.
              </span>
            </h2>
            <a className="red-dot font22 fontW500 hidden-md-up" href="/contact">
              <span>Build Yours</span>
            </a>
            <h2
              className="fontW600 text-center font18 p-b-2"
              style={{ color: "#F47878" }}
            >
              See what we've created.
            </h2>

            <div className="flex flex-wrap align-content-center justify-content-center">
              {[
                "Show All",
                "Website",
                "Mobile",
                "Responsive",
                "Social",
                "Messaging",
                "Payments",
                "Geolocation",
              ].map((item) => {
                return (
                  <div
                    className={
                      this.state.filter
                        ? this.state.filter === item
                          ? "btn btn-filter  fontW500 filter-width"
                          : "btn fontW500 orange-hover filter-width"
                        : item === "Show All"
                        ? "btn btn-filter  fontW500 filter-width"
                        : "btn fontW500 orange-hover filter-width"
                    }
                    onClick={() => {
                      if (item === "Show All") {
                        this.setState({
                          filter: null,
                        });
                      } else {
                        this.setState({
                          filter: item,
                        });
                      }
                    }}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        <section>
          {clients
            .filter((client) => {
              if (this.state.filter) {
                return client.tags.indexOf(this.state.filter) > -1;
              } else {
                return client;
              }
            })
            .map((client) => {
              //console.log(client.tags.indexOf(this.state.filter) > -1)
              return <PortfolioSingle client={client} />;
            })}
        </section>
      </div>
    );
  }
}
