import React, { Component } from "react";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggle: false,
    };
  }

  toggle() {
    this.setState({
      toggle: !this.state.toggle,
    });
  }

  render() {
    let client = this.props.client;

    return (
      <div
        className={this.state.toggle ? "portfolio_toggle relative" : "relative"}
      >
        <div className="portfolio_read_more">
          <div className="p-a-1">
            <div className=" fontW500" style={{ color: "#F47878" }}>
              {client.platforms}
            </div>
            <div className=" fontW500">{client.tech}</div>
            <div className="m-t-1">{client.readMore}</div>
          </div>
        </div>
        <div className="portfolio" style={{ padding: "6em 1em" }}>
          <div className="flex flex-wrap align-content-center justify-content-center">
            <div
              style={{
                backgroundImage: "url(" + client.image + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: "600px",
                height: "350px",
                marginBottom: "1rem",
              }}
            />
            <div className="flex1">
              <div className="fontW500">{client.title}</div>
              <div className="fontW600" style={{ color: "#F47878" }}>
                {client.subtitle}
              </div>
              <div className="m-y-1">{client.desc}</div>
              <div className="flex flex-wrap">
                {client.tags.map((tag) => {
                  return (
                    <div className="btn btn-tag offset-row-padding">{tag}</div>
                  );
                })}
              </div>

              <div className="">
                {client.review && (
                  <div className="flex align-items-center p-t-1">
                    {client.clientFace && (
                      <div
                        style={{
                          backgroundImage: "url(" + client.clientFace + ")",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          width: "3rem",
                          height: "3rem",
                          borderRadius: "3rem",
                          marginRight: "1rem",
                        }}
                      />
                    )}
                    <div className="flex1">
                      "{client.review}"{" "}
                      <span className="inline-block font12">
                        — {client.client}
                      </span>
                    </div>
                  </div>
                )}

                {client.clientName && (
                  <a
                    href={client.clienthttp}
                    target="_blank"
                    rel="noreferrer"
                    className="font12 nodecoration"
                    style={{ marginLeft: "4rem" }}
                  >
                    Visit {client.clientName}
                  </a>
                )}
              </div>

              <div
                className="m-t-1  text-xs-right"
                style={{
                  position: "absolute",
                  bottom: "4rem",
                  right: "0",
                }}
              >
                <div
                  className={
                    this.state.toggle
                      ? "move-100px-right btn orange-hover smooth-transform"
                      : "btn orange-hover smooth-transform"
                  }
                  onClick={() => {
                    this.toggle();
                  }}
                >
                  {this.state.toggle ? "Close" : "Read More"}{" "}
                  <i className="fa fa-arrow-right" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            boxShadow: "inset 0 1px 0 0 rgba(0,0,0,.12)",
            margin: "auto",
            width: "90%",
            height: "1px",
          }}
        />
      </div>
    );
  }
}
